import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { View, Text, TextInput, Button, ImageBackground, KeyboardAvoidingView, Animated, Dimensions, width, height, Image, FlatList, Switch, SafeAreaView, Platform, StatusBar, ScrollView, useWindowDimensions, TouchableOpacity, Modal, Alert, StyleSheet, ActivityIndicator, Linking } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc,getDoc ,collection, getDocs,addDoc,updateDoc, db, query,where } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { LinearGradient } from 'expo-linear-gradient';
import Icon from 'react-native-vector-icons/FontAwesome';
import { FontAwesome } from '@expo/vector-icons';
import Toast from 'react-native-toast-message';
import { Video } from 'expo-av';
import * as WebBrowser from 'expo-web-browser';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



// Configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDk0Gi3wdS62MTuY3kp8NEn-2-AzXwc3w4",
  authDomain: "burkin-up.firebaseapp.com",
  projectId: "burkin-up",
  storageBucket: "burkin-up.appspot.com",
  messagingSenderId: "573275814208",
  appId: "1:573275814208:web:c211315c018a15fe51a2a5",
  measurementId: "G-E5DP1Y8M5P"
};

// Initialiser Firebase uniquement si aucune instance n'existe déjà
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp(); // Utiliser l'application Firebase existante
}

// Initialiser les services Firebase
const auth = getAuth(app);
const firestore = getFirestore(app);
// L'initialisation de Firebase Analytics peut être conditionnelle en fonction de la plateforme
if (Platform.OS !== 'web') {
  getAnalytics(app);
}


const SplashScreen = () => {
  const navigate = useNavigate();

  // Redirection après un certain délai
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/home");
    }, 3000); // Durée de l'affichage du SplashScreen (3 secondes)

    return () => clearTimeout(timer); // Nettoyage du timer
  }, [navigate]);

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-b from-blue-300 to-white">
      <img
        src="/assets/logo.png"
        alt="Logo"
        className="w-32 h-32"
      />
    </div>
  );
};

// --- Home Screen ---
const HomeScreen = ({ navigation }) => {
  const [isSplashVisible, setIsSplashVisible] = useState(true);
  const { width } = useWindowDimensions();

  // Timer de 5 secondes pour afficher le logo pendant le splash screen
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsSplashVisible(false);
    }, 5000); // Masquer le splash après 5 secondes

    return () => clearTimeout(timeout);
  }, []);

  if (isSplashVisible) {
    return (
      <View style={gstyles.splashContainer}>
        <Image source={require('./assets/logon.png')} style={gstyles.logo} />
      </View>
    );
  }

  // Contenu principal de la page d'accueil avec l'image de fond responsive
  return (
    <ImageBackground source={require('./assets/Hpage.jpg')} style={gstyles.backgroundImage}>
      <StatusBar barStyle="light-content" />

      {/* Bouton Admin en haut à droite */}
      <View style={gstyles.adminButtonContainer}>
        <TouchableOpacity style={gstyles.adminButton} onPress={() => navigation.navigate('Admin')}>
          <Text style={gstyles.adminButtonText}>Admin</Text>
        </TouchableOpacity>
      </View>

      <View style={gstyles.container}>
        <View style={gstyles.header}>
          <Text style={gstyles.title}>Burkin'Up</Text>
          <Text style={gstyles.subtitle}>
            Plateforme Burkinabè pour l'Afrique
          </Text>
        </View>

        <View style={gstyles.content}>
          <Text style={gstyles.description}>
            "Évoluez avec notre réseau : mentorat, coaching, formations, opportunités et ressources."
          </Text>

          <View style={gstyles.buttonGroup}>
            <TouchableOpacity
              style={gstyles.buttonPrimary}
              onPress={() => navigation.navigate('Signup')}
            >
              <Text style={gstyles.buttonTextPrimary}>S'inscrire</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={gstyles.buttonSecondary}
              onPress={() => navigation.navigate('Login')}
            >
              <Text style={gstyles.buttonTextSecondary}>Se connecter</Text>
            </TouchableOpacity>
          </View>
        </View>

        <View style={gstyles.footer}>
          <Text style={gstyles.footerText}>
            © 2024 Burkin'Up. Tous droits réservés.
          </Text>
        </View>
      </View>
    </ImageBackground>
  );
};

const gstyles = StyleSheet.create({
  splashContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff', // Fond blanc pendant le splash
  },
  logo: {
    width: 150,
    height: 150,
    resizeMode: 'contain',
  },
  backgroundImage: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  adminButtonContainer: {
    position: 'absolute',
    top: 50,
    right: 20,
  },
  adminButton: {
    backgroundColor: '#000',
    padding: 10,
    borderRadius: 5,
  },
  adminButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    marginBottom: 50,
    alignItems: 'center',
  },
  title: {
    fontSize: 62,
    color: '#fff',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    color: '#fff',
    textAlign: 'center',
  },
  content: {
    alignItems: 'center',
    marginBottom: 30,
  },
  description: {
    fontSize: 16,
    color: '#fff',
    textAlign: 'center',
    marginBottom: 30,
  },
  buttonGroup: {
    width: '80%',
    alignItems: 'center',
  },
  buttonPrimary: {
    backgroundColor: '#0000FF',
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 25,
    marginBottom: 20,
    width: '80%',
    alignItems: 'center',
  },
  buttonSecondary: {
    backgroundColor: '#0000FF',
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 25,
    width: '80%',
    alignItems: 'center',
  },
  buttonTextPrimary: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
  buttonTextSecondary: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
  footer: {
    position: 'absolute',
    bottom: 20,
  },
  footerText: {
    color: '#fff',
  },
});



// --- Signup Screen ---

const SignupScreen = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [phone, setPhone] = useState('');
  const [sex, setSex] = useState('');

  const auth = getAuth();
  const firestore = getFirestore();

  const handleSignup = async () => {
    if (!firstName || !lastName || !email || !password || !confirmPassword || !phone || !birthDate || !sex) {
      showAlert('Erreur', 'Tous les champs doivent être remplis.');
      return;
    }

    if (password !== confirmPassword) {
      showAlert('Erreur', 'Les mots de passe ne correspondent pas.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(firestore, 'users', user.uid), {
        firstName,
        lastName,
        email,
        phone,
        birthDate,
        sex,
      });

      resetFields();

      showAlert('Succès', 'Inscription réussie!');
      navigation.navigate('Login');
    } catch (error) {
      console.error(error);
      showAlert('Erreur', 'Erreur lors de l\'inscription: ' + error.message);
    }
  };

  const resetFields = () => {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setFirstName('');
    setLastName('');
    setBirthDate('');
    setPhone('');
    setSex('');
  };

  const showAlert = (title, message) => {
    if (Platform.OS === 'web') {
      window.alert(`${title}: ${message}`);
    } else {
      Alert.alert(title, message);
    }
  };

  return (
    <ImageBackground
      source={require('./assets/bg-image.jpg')}
      style={lstyles.background}
      resizeMode="cover"
    >
      <KeyboardAvoidingView style={lstyles.container} behavior={Platform.OS === "ios" ? "padding" : "height"}>
        <ScrollView
          contentContainerStyle={lstyles.scrollView}
          showsVerticalScrollIndicator={true} // Montre la barre de défilement
          style={lstyles.scrollContainer}
        >
          <View style={lstyles.signupContainer}>
            <Text style={lstyles.signupTitle}>Créer un Compte</Text>

            <TextInput
              style={lstyles.signupInput}
              placeholder="Prénom"
              placeholderTextColor="#fff"
              value={firstName}
              onChangeText={setFirstName}
            />
            <TextInput
              style={lstyles.signupInput}
              placeholder="Nom"
              placeholderTextColor="#fff"
              value={lastName}
              onChangeText={setLastName}
            />
            <TextInput
              style={lstyles.signupInput}
              placeholder="Email"
              placeholderTextColor="#fff"
              value={email}
              onChangeText={setEmail}
              keyboardType="email-address"
            />
            <TextInput
              style={lstyles.signupInput}
              placeholder="Mot de passe"
              placeholderTextColor="#fff"
              secureTextEntry
              value={password}
              onChangeText={setPassword}
            />
            <TextInput
              style={lstyles.signupInput}
              placeholder="Confirmer le mot de passe"
              placeholderTextColor="#fff"
              secureTextEntry
              value={confirmPassword}
              onChangeText={setConfirmPassword}
            />
            <TextInput
              style={lstyles.signupInput}
              placeholder="Numéro de téléphone"
              placeholderTextColor="#fff"
              value={phone}
              onChangeText={setPhone}
              keyboardType="phone-pad"
            />
            <TextInput
              style={lstyles.signupInput}
              placeholder="Date de naissance (JJ/MM/AAAA)"
              placeholderTextColor="#fff"
              value={birthDate}
              onChangeText={setBirthDate}
            />
            <TextInput
              style={lstyles.signupInput}
              placeholder="Sexe"
              placeholderTextColor="#fff"
              value={sex}
              onChangeText={setSex}
            />

            <TouchableOpacity style={lstyles.signupButton} onPress={handleSignup}>
              <Text style={lstyles.signupButtonText}>S'inscrire</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => navigation.navigate('PrivacyPolicy')} style={lstyles.termsContainer}>
              <Text style={lstyles.termsText}>
                En vous inscrivant, vous acceptez les{" "}
                <Text style={lstyles.linkText}>Conditions d'utilisation</Text>.
              </Text>
            </TouchableOpacity>
          </View>
        </ScrollView>

        <Text style={lstyles.footer}>Burkin'Up</Text>
      </KeyboardAvoidingView>
    </ImageBackground>
  );
};



const lstyles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%',
    height: '100%', // Assure que l'image de fond couvre l'écran entier
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20, // Centrer le contenu
  },
  scrollContainer: {
    width: '100%',
    flexGrow: 1, // Assure que le ScrollView prend tout l'espace vertical
  },
  scrollView: {
    paddingVertical: 20, // Espacement vertical pour que le défilement fonctionne
    justifyContent: 'center',
    alignItems: 'center',
  },
  signupContainer: {
    width: '100%', // Prend toute la largeur disponible pour éviter les divisions d'images
    maxWidth: 400, // Limite la largeur pour ne pas trop étendre les champs
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Ajoute un fond semi-transparent pour le formulaire
    padding: 20,
    borderRadius: 10,
  },
  signupTitle: {
    fontSize: 24,
    color: '#fff',
    textAlign: 'center',
    marginBottom: 20, // Espace entre le titre et le reste
  },
  signupInput: {
    borderBottomWidth: 1,
    borderBottomColor: '#fff',
    marginBottom: 15,
    fontSize: 16,
    color: '#fff',
    paddingVertical: 10,
  },
  signupButton: {
    backgroundColor: '#007BFF', // Couleur du bouton
    paddingVertical: 12,
    borderRadius: 8,
    marginTop: 20,
    alignItems: 'center',
  },
  signupButtonText: {
    color: '#fff',
    fontSize: 18,
  },
  termsContainer: {
    marginTop: 10,
    alignItems: 'center',
  },
  termsText: {
    color: '#fff',
    fontSize: 12,
    textAlign: 'center',
  },
  linkText: {
    color: '#00f',
    textDecorationLine: 'underline',
  },
  footer: {
    color: '#fff',
    textAlign: 'center',
    marginVertical: 10,
  },
});












// --- Login Screen ---
const LoginScreen = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(getAuth(), email, password);

      // Notification de succès pour Android et iOS
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        Alert.alert('Connexion réussie!', 'Vous êtes connecté.');
      }

      // Notification de succès pour Web
      if (Platform.OS === 'web') {
        window.alert('Connexion réussie!');
      }

      navigation.navigate('Dashboard'); // Redirection après la connexion réussie
    } catch (error) {
      // Notifications d'erreur pour Android et iOS
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        Alert.alert('Erreur', error.message);
      }

      // Notifications d'erreur pour Web
      if (Platform.OS === 'web') {
        window.alert('Erreur : ' + error.message);
      }
    }
  };

  return (
    <ImageBackground
      source={require('./assets/bg-image.jpg')}
      style={loginStyles.background}
      resizeMode="cover" // Pour rendre l'image responsive
    >
      <KeyboardAvoidingView
        style={loginStyles.container}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <ScrollView contentContainerStyle={loginStyles.scrollView}>
          <View style={loginStyles.loginContainer}>
            <Text style={loginStyles.loginTitle}>Connexion</Text>

            <TextInput
              style={loginStyles.loginInput}
              placeholder="Email"
              placeholderTextColor="#fff"
              value={email}
              onChangeText={setEmail}
              keyboardType="email-address"
              autoCapitalize="none"
            />
            <TextInput
              style={loginStyles.loginInput}
              placeholder="Mot de passe"
              placeholderTextColor="#fff"
              secureTextEntry
              value={password}
              onChangeText={setPassword}
            />

            <TouchableOpacity style={loginStyles.loginButton} onPress={handleLogin}>
              <Text style={loginStyles.loginButtonText}>Se connecter</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => navigation.navigate('Signup')} style={loginStyles.signupLink}>
              <Text style={loginStyles.signupText}>
                Pas encore de compte?{' '}
                <Text style={loginStyles.linkText}>S'inscrire</Text>
              </Text>
            </TouchableOpacity>
          </View>
        </ScrollView>

        <Text style={loginStyles.footer}>Burkin'Up</Text>
      </KeyboardAvoidingView>
    </ImageBackground>
  );
};
// --- Resources Screen ---
const ResourcesScreen = () => {
  const [resources, setResources] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State to manage search input
  const [filteredResources, setFilteredResources] = useState([]);
  const firestore = getFirestore();

  // Fetch resources from Firestore
  useEffect(() => {
    const fetchResources = async () => {
      const resourcesCollection = collection(firestore, 'ressources');
      const resourcesSnapshot = await getDocs(resourcesCollection);
      const resourcesList = resourcesSnapshot.docs.map(doc => doc.data());
      setResources(resourcesList);
      setFilteredResources(resourcesList); // Initialize filtered list with all resources
    };

    fetchResources();
  }, [firestore]);

  // Function to open URL
  const handleLinkPress = (url) => {
    if (url) {
      Linking.openURL(url);
    }
  };

  // Function to handle search input and filter resources
  const handleSearch = (text) => {
    setSearchQuery(text);
    const filteredList = resources.filter((resource) =>
      resource.title.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredResources(filteredList);
  };

  if (resources.length === 0) {
    return <Text>Loading...</Text>;
  }

  return (
    <View style={resourcesStyles.background}>
      <ScrollView contentContainerStyle={resourcesStyles.container}>
        <Text style={resourcesStyles.title}>Ressources Éducatives pour Jeunes Entrepreneurs</Text>

        {/* Search Bar */}
        <TextInput
          style={resourcesStyles.searchInput}
          placeholder="Rechercher une ressource..."
          placeholderTextColor="#ccc"
          value={searchQuery}
          onChangeText={handleSearch}
        />

        {/* Display filtered resources */}
        {filteredResources.length > 0 ? (
          filteredResources.map((resource, index) => (
            <TouchableOpacity
              key={index}
              style={resourcesStyles.linkContainer}
              onPress={() => handleLinkPress(resource.url)}
            >
              <Text style={resourcesStyles.link}>{resource.title}</Text>
            </TouchableOpacity>
          ))
        ) : (
          <Text style={resourcesStyles.noResults}>Aucune ressource trouvée</Text>
        )}
      </ScrollView>
    </View>
  );
};

//Dashboard


const DashboardScreen = ({ navigation }) => {
  const [username, setUsername] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [announcement, setAnnouncement] = useState('');
  const [scrollAnim] = useState(new Animated.Value(Dimensions.get('window').width));

  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUsername(userDoc.data().firstName || 'Utilisateur');
        }
      }
    };

    const fetchAnnouncement = async () => {
      const announcementRef = collection(firestore, 'getValue');
      const q = query(announcementRef, where('Message', '!=', ''));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setAnnouncement(querySnapshot.docs[0].data().Message);
      }
    };

    fetchUserData();
    fetchAnnouncement();
  }, [auth.currentUser, firestore]);

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(scrollAnim, {
          toValue: -Dimensions.get('window').width,
          duration: 120000,
          useNativeDriver: true,
        }),
        Animated.timing(scrollAnim, {
          toValue: Dimensions.get('window').width,
          duration: 0,
          useNativeDriver: true,
        }),
        Animated.delay(10000)
      ]),
    ).start();
  }, [scrollAnim]);

  return (
    <ImageBackground
      source={require('./assets/image.jpg')}
      style={Dastyles.background}
      imageStyle={Dastyles.imageStyle}
    >
      <LinearGradient
        colors={['rgba(0, 0, 0, 0.6)', 'rgba(0, 0, 0, 0.1)']}
        style={Dastyles.overlay}
      >
        <View style={Dastyles.container}>
          <View style={Dastyles.header}>
            <Text style={Dastyles.brandName}>BURKIN'UP</Text>
            <Text style={Dastyles.welcomeText}>Bienvenue, {username}!</Text>
          </View>

          <View style={Dastyles.menuBar}>
            <Text style={Dastyles.menuTitle}>Menu</Text>
            <View style={Dastyles.menuItems}>
              <TouchableOpacity onPress={() => navigation.navigate('FindMentor')}>
                <Text style={Dastyles.menuItemText}>Trouver un mentor</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => navigation.navigate('Training')}>
                <Text style={Dastyles.menuItemText}>Formations</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => navigation.navigate('Opportunities')}>
                <Text style={Dastyles.menuItemText}>Opportunités</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => navigation.navigate('FindCoach')}>
                <Text style={Dastyles.menuItemText}>Trouver un Coach</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => navigation.navigate('FindTrainer')}>
                <Text style={Dastyles.menuItemText}>Trouver un Formateur</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => navigation.navigate('Funding')}>
                <Text style={Dastyles.menuItemText}>Financement de Projet</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => navigation.navigate('GovernmentInfo')}>
                <Text style={Dastyles.menuItemText}>Informations Gouvernementales</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => navigation.navigate('Resources')}>
                <Text style={Dastyles.menuItemText}>Ressources</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => navigation.navigate('Settings')}>
                <Text style={Dastyles.menuItemText}>Paramètres</Text>
              </TouchableOpacity>
            </View>
          </View>

          <Animated.View style={[Dastyles.announcement, { transform: [{ translateX: scrollAnim }] }]}>
            <Text style={Dastyles.announcementText}>{announcement}</Text>
          </Animated.View>
        </View>
      </LinearGradient>
    </ImageBackground>
  );
};



// Styles
const Dastyles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageStyle: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  overlay: {
    flex: 1,
    justifyContent: 'flex-start',
    width: '100%',
  },
  container: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    paddingTop: 0,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingVertical: 10,
    paddingHorizontal: 20,
    width: '100%',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  brandName: {
    fontSize: 30,
    fontWeight: 'bold',
    color: '#000',
  },
  welcomeText: {
    fontSize: 20,
    color: '#000',
    textAlign: 'center',
    flex: 1,
  },
  menuBar: {
    backgroundColor: '#000',
    opacity: 0.6,
    width: '100%',
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 0, // Aucune marge entre le header et la barre de menu
  },
  menuTitle: {
    color: '#fff',
    fontSize: 20,
    marginBottom: 5,
  },
  menuItems: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  menuItemText: {
    color: '#fff',
    fontSize: 16,
    marginHorizontal: 10,
    marginVertical: 5,
  },
  announcement: {
    position: 'absolute',
    bottom: 50,
    left: 0,
    right: 0,
  },
  announcementText: {
    color: '#fff',
    fontSize: 18,
  },
});







const FindCoachScreen = () => {
  const [coaches, setCoaches] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [requestMessage, setRequestMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchCoaches = async () => {
      const coachCollection = collection(firestore, 'coaches');
      const coachSnapshot = await getDocs(coachCollection);
      const coachList = coachSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCoaches(coachList);
    };

    fetchCoaches();
  }, []);

  const handleContactCoach = async () => {
    if (selectedCoach && requestMessage) {
      const user = auth.currentUser;
      await addDoc(collection(firestore, 'coachRequests'), {
        coachName: `${selectedCoach.firstName} ${selectedCoach.lastName}`,
        userName: user.displayName || user.email,
        userEmail: user.email,
        requestMessage,
      });

      // Augmenter le compteur de contacts de 1
      const coachRef = doc(firestore, 'coaches', selectedCoach.id);
      await updateDoc(coachRef, {
        contactsCount: (selectedCoach.contactsCount || 0) + 1,
      });

      setModalVisible(false);
      setRequestMessage('');
      alert('Votre demande a été envoyée avec succès.');
    } else {
      alert('Veuillez entrer un message avant d\'envoyer.');
    }
  };

  const handleRating = async (coachId, rating) => {
    try {
      const coachRef = doc(firestore, 'coaches', coachId);
      const user = auth.currentUser;
      const userRatingRef = doc(firestore, 'coaches', coachId, 'ratings', user.uid);

      const userRatingDoc = await getDoc(userRatingRef);

      let newRatingCount = 0;
      let newRatingTotal = 0;

      if (userRatingDoc.exists()) {
        const oldRating = userRatingDoc.data().rating;
        const coachDoc = await getDoc(coachRef);
        const coachData = coachDoc.data();

        newRatingTotal = coachData.ratingTotal - oldRating + rating;
        newRatingCount = coachData.ratingCount;

        await updateDoc(userRatingRef, {
          rating: rating,
        });
      } else {
        const coachDoc = await getDoc(coachRef);
        const coachData = coachDoc.data();

        newRatingTotal = (coachData.ratingTotal || 0) + rating;
        newRatingCount = (coachData.ratingCount || 0) + 1;

        await setDoc(userRatingRef, {
          rating: rating,
        });
      }

      const newAverageRating = newRatingTotal / newRatingCount;

      await updateDoc(coachRef, {
        rating: newAverageRating,
        ratingCount: newRatingCount,
        ratingTotal: newRatingTotal,
      });

      // Envoi de notification de succès via console
      console.log('Note mise à jour avec succès! Nouvelle moyenne :', newAverageRating);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la note :', error);
    }
  };

  // Fonction pour filtrer les entraîneurs par spécialité
  const filteredCoaches = coaches.filter(coach =>
    coach.specialty.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <ScrollView contentContainerStyle={thstyles.container}>
      <TextInput
        style={thstyles.searchInput}
        placeholder="Rechercher par spécialité"
        value={searchTerm}
        onChangeText={setSearchTerm}
      />
      {filteredCoaches.map(coach => (
        <View key={coach.id} style={thstyles.mentorCard}>
          <Image source={{ uri: coach.photoUrl }} style={thstyles.mentorImageCircle} />
          <Text style={thstyles.mentorName}>{coach.firstName} {coach.lastName}</Text>
          <Text style={thstyles.mentorSpecialty}>{coach.specialty || 'Aucune spécialité spécifiée'}</Text>

          <View style={thstyles.ratingContainer}>
            {[1, 2, 3, 4, 5].map(star => (
              <TouchableOpacity key={star} onPress={() => handleRating(coach.id, star)}>
                <FontAwesome
                  name="star"
                  size={24}
                  color={coach.rating >= star ? "gold" : "gray"}
                />
              </TouchableOpacity>
            ))}
          </View>

          <Text style={thstyles.contactsCount}>
            {coach.contactsCount || 0} contacts
          </Text>

          <TouchableOpacity
            style={thstyles.contactButton}
            onPress={() => {
              setSelectedCoach(coach);
              setModalVisible(true);
            }}
          >
            <Text style={thstyles.contactButtonText}>Contactez-le</Text>
          </TouchableOpacity>
        </View>
      ))}

      {selectedCoach && (
        <Modal
          visible={modalVisible}
          transparent={true}
          animationType="slide"
        >
          <View style={thstyles.modalContainer}>
            <View style={thstyles.modalContent}>
              <Text style={thstyles.modalTitle}>Pourquoi avez-vous besoin de {selectedCoach.firstName} ?</Text>
              <TextInput
                style={thstyles.input}
                placeholder="Expliquez-vous clairement"
                value={requestMessage}
                onChangeText={setRequestMessage}
                multiline={true}
              />
              <Button title="Envoyer" onPress={handleContactCoach} />
              <Button title="Annuler" onPress={() => setModalVisible(false)} />
            </View>
          </View>
        </Modal>
      )}
    </ScrollView>
  );
};




const GovernementInfoScreen = ({ navigation }) => {
  // Similaire à FindMentorScreen avec des ajustements pour les formateurs
};
//FindMentor
const ToastComponent = forwardRef((props, ref) => (
  <Toast {...props} ref={ref} />
));


const FindMentorScreen = () => {
  const [mentors, setMentors] = useState([]);
  const [filteredMentors, setFilteredMentors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [requestMessage, setRequestMessage] = useState('');
  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        const mentorCollection = collection(firestore, 'mentors');
        const mentorSnapshot = await getDocs(mentorCollection);
        const mentorList = mentorSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setMentors(mentorList);
        setFilteredMentors(mentorList);
      } catch (error) {
        console.error('Erreur lors de la récupération des mentors :', error);
      }
    };

    fetchMentors();
  }, [firestore]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query === '') {
      setFilteredMentors(mentors);
    } else {
      const filtered = mentors.filter(mentor =>
        mentor.specialty.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredMentors(filtered);
    }
  };

  const handleRating = async (mentorId, rating) => {
    try {
      const mentorRef = doc(firestore, 'mentors', mentorId);
      const user = auth.currentUser;
      const userRatingRef = doc(firestore, 'mentors', mentorId, 'ratings', user.uid);

      const userRatingDoc = await getDoc(userRatingRef);

      let newRatingCount = 0;
      let newRatingTotal = 0;

      if (userRatingDoc.exists()) {
        const oldRating = userRatingDoc.data().rating;
        const mentorDoc = await getDoc(mentorRef);
        const mentorData = mentorDoc.data();

        newRatingTotal = mentorData.ratingTotal - oldRating + rating;
        newRatingCount = mentorData.ratingCount;

        await updateDoc(userRatingRef, {
          rating: rating,
        });
      } else {
        const mentorDoc = await getDoc(mentorRef);
        const mentorData = mentorDoc.data();

        newRatingTotal = (mentorData.ratingTotal || 0) + rating;
        newRatingCount = (mentorData.ratingCount || 0) + 1;

        await setDoc(userRatingRef, {
          rating: rating,
        });
      }

      const newAverageRating = newRatingTotal / newRatingCount;

      await updateDoc(mentorRef, {
        rating: newAverageRating,
        ratingCount: newRatingCount,
        ratingTotal: newRatingTotal,
      });

      console.log('Note mise à jour avec succès! Nouvelle moyenne :', newAverageRating);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la note :', error);
    }
  };


  const handleContactMentor = async () => {
    if (selectedMentor && requestMessage) {
      const user = auth.currentUser;
      await addDoc(collection(firestore, 'mentorRequests'), {
        mentorName: `${selectedMentor.firstName} ${selectedMentor.lastName}`,
        userName: user.displayName || user.email,
        userEmail: user.email,
        requestMessage,
      });

      // Mise à jour du compteur de contacts
      const mentorRef = doc(firestore, 'mentors', selectedMentor.id);
      await updateDoc(mentorRef, {
        contactsCount: (selectedMentor.contactsCount || 0) + 1,
      });

      // Réinitialiser l'état
      setModalVisible(false);
      setRequestMessage('');
      alert('Votre demande a été envoyée avec succès.');
    } else {
      alert('Veuillez entrer un message avant d\'envoyer.');
    }
  };


  const renderMentorItem = ({ item: mentor }) => (
    <TouchableOpacity style={finstyles.mentorCard} onPress={() => setSelectedMentor(mentor)}>
      <Image source={{ uri: mentor.photoUrl }} style={finstyles.mentorImageCircle} />
      <Text style={finstyles.mentorName}>{mentor.firstName} {mentor.lastName}</Text>
      <Text style={finstyles.mentorSpecialty}>{mentor.specialty || 'Aucune spécialité spécifiée'}</Text>
      <View style={finstyles.ratingContainer}>
        {[1, 2, 3, 4, 5].map(star => (
          <TouchableOpacity key={star} onPress={() => handleRating(mentor.id, star)}>
            <FontAwesome
              name={mentor.rating >= star ? "star" : "star-o"}
              size={24}
              color="gold"
            />
          </TouchableOpacity>
        ))}
        <Text style={finstyles.ratingText}>
          {mentor.rating ? mentor.rating.toFixed(1) : 'Pas encore noté'} / 5
        </Text>
      </View>
      <Text style={finstyles.contactsCount}>
        {mentor.contactsCount || 0} contacts
      </Text>
    </TouchableOpacity>
  );

  return (
    <KeyboardAvoidingView style={finstyles.container} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
      <View style={finstyles.pageTitleContainer}>
        <Text style={finstyles.pageTitle}>Trouver un Mentor</Text>
      </View>

      <TextInput
        style={finstyles.searchInput}
        placeholder="Rechercher par spécialité..."
        value={searchQuery}
        onChangeText={handleSearch}
      />

      <View style={finstyles.mainContent}>
        {/* Liste des mentors */}
        <FlatList
          data={filteredMentors}
          renderItem={renderMentorItem}
          keyExtractor={mentor => mentor.id}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={finstyles.mentorList}
        />

        {/* Détails du mentor sélectionné */}
        <View style={finstyles.mentorDetailContainer}>
          <ScrollView>
            {selectedMentor ? (
              <>
                <Image source={{ uri: selectedMentor.photoUrl }} style={finstyles.mentorDetailImage} />
                <Text style={finstyles.mentorDetailName}>
                  {selectedMentor.firstName} {selectedMentor.lastName}
                  {selectedMentor.isActive && ( // Badge affiché si isActive est vrai
                    <Text style={finstyles.badge}>Certifié</Text>
                  )}
                </Text>
                <Text style={finstyles.mentorDetailSpecialty}>{selectedMentor.specialty}</Text>
                <Text style={finstyles.mentorDetailDescription}>{selectedMentor.mentorDetail}</Text>
                <TouchableOpacity style={finstyles.contactButton} onPress={() => setModalVisible(true)}>
                  <Text style={finstyles.contactButtonText}>Contacter</Text>
                </TouchableOpacity>
              </>
            ) : (
              <Text style={finstyles.noSelectionText}>Sélectionnez un mentor pour voir les détails</Text>
            )}
          </ScrollView>
        </View>
      </View>

      {/* Modal pour contacter le mentor */}
      {selectedMentor && (
        <Modal
          visible={modalVisible}
          transparent={true}
          animationType="slide"
        >
          <View style={finstyles.modalContainer}>
            <View style={finstyles.modalContent}>
              <Text style={finstyles.modalTitle}>Pourquoi avez-vous besoin de {selectedMentor.firstName} ?</Text>
              <TextInput
                style={finstyles.input}
                placeholder="Expliquez-vous clairement"
                value={requestMessage}
                onChangeText={setRequestMessage}
                multiline={true}
              />
              <Button title="Envoyer" onPress={handleContactMentor} />
              <Button title="Annuler" onPress={() => setModalVisible(false)} />
            </View>
          </View>
        </Modal>
      )}

      {/* Notifications Toast */}
      <Toast ref={(ref) => Toast.setRef(ref)} />
    </KeyboardAvoidingView>
  );
  };



const finstyles = {
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: 'white',
  },
  pageTitleContainer: {
    alignItems: 'center',
    marginBottom: 16,
  },
  pageTitle: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  searchInput: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
    padding: 8,
    marginBottom: 16,
  },
  mainContent: {
    flexDirection: 'row',
    flex: 1,
  },
  mentorListContainer: {
    flexBasis: '55%',
    marginRight: 8,
  },
  mentorCard: {
    width: 250,
    height: 300,
    borderRadius: 8,
    backgroundColor: '#f0f0f0',
    padding: 12,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
    position: 'relative', // Positionnement relatif pour l'image fixe
  },
  mentorImageCircle: {
    width: 70,
    height: 70,
    borderRadius: 35,
    marginBottom: 8,
    resizeMode: 'cover',
    position: 'absolute', // Image reste fixe
    top: 20, // Positionnée en haut de la carte
  },
  mentorName: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 60, // Pour que le texte commence sous l'image
  },
  mentorSpecialty: {
    fontSize: 14,
    color: 'gray',
  },
  mentorDetailContainer: {
    flexBasis: '45%',
    padding: 16,
    backgroundColor: '#f9f9f9',
    borderRadius: 8,
  },
  mentorDetailImage: {
    width: 150,
    height: 150,
    borderRadius: 75, // Cercle parfait
    marginBottom: 16,
    alignSelf: 'center',
    resizeMode: 'cover',
  },
  mentorDetailName: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
    textAlign: 'center',
  },
  mentorDetailSpecialty: {
    fontSize: 16,
    fontStyle: 'italic', // Texte en italique
    color: 'green', // Texte en vert
    marginBottom: 8,
    textAlign: 'center',
  },
  mentorDetailDescription: {
    fontSize: 14,
    marginBottom: 16,
    textAlign: 'center',
  },
  noSelectionText: {
    textAlign: 'center',
    fontSize: 16,
    color: 'gray',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: '80%',
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 16,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
    padding: 8,
    height: 100,
    marginBottom: 16,
  },
  contactButton: {
    backgroundColor: 'blue',
    borderRadius: 4,
    padding: 12,
    alignItems: 'center',
  },
  contactButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  mentorList: {
    paddingVertical: 18,
  },
  ratingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  badge: {
    backgroundColor: 'green',
    borderRadius: 18,
    padding: 4,
    marginLeft: 8,
    color: 'white',
    fontSize: 12,
    fontWeight: 'regular',
  },
};


//Training 

const TrainingScreen = () => {
  const [trainings, setTrainings] = useState([]);
  const [filteredTrainings, setFilteredTrainings] = useState([]);
  const [selectedTraining, setSelectedTraining] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRegistrationModalVisible, setIsRegistrationModalVisible] = useState(false);
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [searchText, setSearchText] = useState('');

  const firestore = getFirestore();

  useEffect(() => {
    const fetchTrainings = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'ongoingTraining'));
      const trainingData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTrainings(trainingData);
      setFilteredTrainings(trainingData); // Initialiser avec toutes les formations
    };

    fetchTrainings();
  }, []);

  // Fonction de recherche
  const handleSearch = (text) => {
    setSearchText(text);
    if (text) {
      const filteredData = trainings.filter(training =>
        training.name.toLowerCase().includes(text.toLowerCase())
      );
      setFilteredTrainings(filteredData);
    } else {
      setFilteredTrainings(trainings); // Renvoyer à toutes les formations si la recherche est vide
    }
  };

  const handleLearnMore = (training) => {
    setSelectedTraining(training);
    setIsModalVisible(true);
  };

  const handleRegister = async () => {
    try {
      await addDoc(collection(firestore, 'training'), {
        trainingId: selectedTraining.id,
        trainingName: selectedTraining.name,
        userName,
        userPhone,
      });
      setIsRegistrationModalVisible(false);
      alert('Inscription réussie!');
    } catch (error) {
      alert('Erreur: ' + error.message);
    }
  };

  return (
    <View style={trainstyles.container}>
      <Text style={trainstyles.title}>Formations Disponibles</Text>
      <TextInput
        style={trainstyles.searchInput}
        placeholder="Rechercher par titre de formation"
        value={searchText}
        onChangeText={handleSearch}
      />
      <ScrollView contentContainerStyle={trainstyles.scrollContainer}>
        {filteredTrainings.map(training => (
          <View key={training.id} style={trainstyles.trainingCard}>
            <Text style={trainstyles.trainingTitle}>{training.name}</Text>
            <TouchableOpacity
              style={trainstyles.learnMoreButton}
              onPress={() => handleLearnMore(training)}
            >
              <Text style={trainstyles.buttonText}>En savoir plus</Text>
            </TouchableOpacity>
          </View>
        ))}

        {selectedTraining && (
          <Modal
            visible={isModalVisible}
            animationType="slide"
            transparent={true}
            onRequestClose={() => setIsModalVisible(false)}
          >
            <View style={trainstyles.modalContainer}>
              <Text style={trainstyles.modalTitle}>{selectedTraining.name}</Text>
              <Text style={trainstyles.modalText}>Date: {selectedTraining.date}</Text>
              <Text style={trainstyles.modalText}>Lieu: {selectedTraining.location}</Text>
              <Text style={trainstyles.modalText}>Résumé: {selectedTraining.content}</Text>
              <Text style={trainstyles.modalText}>Prix: {selectedTraining.price} FCFA</Text>
              <TouchableOpacity
                style={trainstyles.registerButton}
                onPress={() => {
                  setIsModalVisible(false);
                  setIsRegistrationModalVisible(true);
                }}
              >
                <Text style={trainstyles.buttonText}>S'inscrire</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={trainstyles.cancelButton}
                onPress={() => setIsModalVisible(false)}
              >
                <Text style={trainstyles.buttonText}>Annuler</Text>
              </TouchableOpacity>
            </View>
          </Modal>
        )}

        <Modal
          visible={isRegistrationModalVisible}
          animationType="slide"
          transparent={true}
          onRequestClose={() => setIsRegistrationModalVisible(false)}
        >
          <View style={trainstyles.modalContainer}>
            <Text style={trainstyles.modalTitle}>Inscription</Text>
            <TextInput
              style={trainstyles.input}
              placeholder="Nom et prénom"
              value={userName}
              onChangeText={setUserName}
            />
            <TextInput
              style={trainstyles.input}
              placeholder="Numéro WhatsApp"
              value={userPhone}
              onChangeText={setUserPhone}
            />
            <TouchableOpacity
              style={trainstyles.registerButton}
              onPress={handleRegister}
            >
              <Text style={trainstyles.buttonText}>Envoyer</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={trainstyles.cancelButton}
              onPress={() => setIsRegistrationModalVisible(false)}
            >
              <Text style={trainstyles.buttonText}>Annuler</Text>
            </TouchableOpacity>
          </View>
        </Modal>
      </ScrollView>
    </View>
  );
};




//Opportunities

const OpportunitiesScreen = () => {
  const [opportunities, setOpportunities] = useState([]);
  const [filteredOpportunities, setFilteredOpportunities] = useState([]);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRegistrationModalVisible, setIsRegistrationModalVisible] = useState(false);
  const [userName, setUserName] = useState('');
  const [userSurname, setUserSurname] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [searchText, setSearchText] = useState('');

  const firestore = getFirestore();

  useEffect(() => {
    const fetchOpportunities = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'opportunity'));
      const opportunityData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setOpportunities(opportunityData);
      setFilteredOpportunities(opportunityData); // Initialiser avec toutes les opportunités
    };

    fetchOpportunities();
  }, []);

  // Fonction de recherche par type d'opportunité
  const handleSearch = (text) => {
    setSearchText(text);
    if (text) {
      const filteredData = opportunities.filter(opportunity =>
        opportunity.type.toLowerCase().includes(text.toLowerCase())
      );
      setFilteredOpportunities(filteredData);
    } else {
      setFilteredOpportunities(opportunities); // Renvoyer toutes les opportunités si la recherche est vide
    }
  };

  const handleLearnMore = (opportunity) => {
    setSelectedOpportunity(opportunity);
    setIsModalVisible(true);
  };

  const handleRegister = async () => {
    try {
      await addDoc(collection(firestore, 'certificate'), {
        opportunityId: selectedOpportunity.id,
        opportunityType: selectedOpportunity.type,
        userName,
        userSurname,
        userEmail,
        userPassword,
      });
      setIsRegistrationModalVisible(false);
      alert('Inscription réussie!');
    } catch (error) {
      alert('Erreur: ' + error.message);
    }
  };

  return (
    <ImageBackground
      source={{ uri: 'https://path-to-your-background-image.jpg' }}
      style={oppstyles.backgroundImage}
    >
      <View style={oppstyles.fixedHeader}>
        <Text style={oppstyles.title}>Opportunités Disponibles</Text>
        <TextInput
          style={oppstyles.searchInput}
          placeholder="Rechercher par type d'opportunité"
          value={searchText}
          onChangeText={handleSearch}
        />
      </View>

      <ScrollView contentContainerStyle={oppstyles.scrollContainer}>
        {filteredOpportunities.map(opportunity => (
          <View key={opportunity.id} style={oppstyles.opportunityCard}>
            <Text style={oppstyles.opportunityType}>{opportunity.type}</Text>
            <Text style={oppstyles.opportunityTitle}>{opportunity.title}</Text>
            <TouchableOpacity
              style={oppstyles.learnMoreButton}
              onPress={() => handleLearnMore(opportunity)}
            >
              <Text style={oppstyles.buttonText}>En savoir plus</Text>
            </TouchableOpacity>
          </View>
        ))}

        {selectedOpportunity && (
          <Modal
            visible={isModalVisible}
            animationType="slide"
            transparent={true}
            onRequestClose={() => setIsModalVisible(false)}
          >
            <View style={oppstyles.modalContainer}>
              <Text style={oppstyles.modalTitle}>{selectedOpportunity.title}</Text>
              <Text style={oppstyles.modalText}>Description: {selectedOpportunity.description}</Text>
              <Text style={oppstyles.modalText}>Date limite: {selectedOpportunity.deadline}</Text>
              <TouchableOpacity
                style={oppstyles.registerButton}
                onPress={() => {
                  setIsModalVisible(false);
                  setIsRegistrationModalVisible(true);
                }}
              >
                <Text style={oppstyles.buttonText}>S'inscrire</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={oppstyles.cancelButton}
                onPress={() => setIsModalVisible(false)}
              >
                <Text style={oppstyles.buttonText}>Annuler</Text>
              </TouchableOpacity>
            </View>
          </Modal>
        )}

        <Modal
          visible={isRegistrationModalVisible}
          animationType="slide"
          transparent={true}
          onRequestClose={() => setIsRegistrationModalVisible(false)}
        >
          <View style={oppstyles.modalContainer}>
            <Text style={oppstyles.modalTitle}>Inscription</Text>
            <TextInput
              style={oppstyles.input}
              placeholder="Nom"
              value={userSurname}
              onChangeText={setUserSurname}
            />
            <TextInput
              style={oppstyles.input}
              placeholder="Prénom"
              value={userName}
              onChangeText={setUserName}
            />
            <TextInput
              style={oppstyles.input}
              placeholder="Email"
              value={userEmail}
              onChangeText={setUserEmail}
            />
            <TextInput
              style={oppstyles.input}
              placeholder="Numéro de téléphone"
              secureTextEntry
              value={userPassword}
              onChangeText={setUserPassword}
            />
            <TouchableOpacity
              style={oppstyles.registerButton}
              onPress={handleRegister}
            >
              <Text style={oppstyles.buttonText}>Envoyer</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={oppstyles.cancelButton}
              onPress={() => setIsRegistrationModalVisible(false)}
            >
              <Text style={oppstyles.buttonText}>Annuler</Text>
            </TouchableOpacity>
          </View>
        </Modal>

        <View style={oppstyles.footer}>
          <Text style={oppstyles.footerText}>© 2024 Burkin'Up. Tous droits réservés.</Text>
        </View>
      </ScrollView>
    </ImageBackground>
  );
};


const SettingsScreen = ({ navigation }) => {
  const auth = getAuth();
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState('fr'); // Valeur par défaut: français

  // Fonction pour se déconnecter
  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigation.navigate('Login'); // Redirection vers la page de connexion après la déconnexion
    } catch (error) {
      console.error('Erreur de déconnexion: ', error);
    }
  };

  return (
    <View style={setstyles.container}>
      <Text style={setstyles.title}>Paramètres</Text>

      {/* Section des Notifications */}
      <View style={setstyles.section}>
        <Text style={setstyles.label}>Notifications</Text>
        <View style={setstyles.switchContainer}>
          <Text style={setstyles.switchLabel}>Activer les notifications</Text>
          <Switch
            value={notificationsEnabled}
            onValueChange={(value) => setNotificationsEnabled(value)}
          />
        </View>
      </View>

      {/* Section du Choix de la Langue */}
      <View style={setstyles.section}>
        <Text style={setstyles.label}>Langue</Text>
        <View style={setstyles.languageContainer}>
          <TouchableOpacity
            style={selectedLanguage === 'fr' ? setstyles.languageButtonSelected : setstyles.languageButton}
            onPress={() => setSelectedLanguage('fr')}
          >
            <Text style={setstyles.languageButtonText}>Français</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={selectedLanguage === 'en' ? setstyles.languageButtonSelected : setstyles.languageButton}
            onPress={() => setSelectedLanguage('en')}
          >
            <Text style={setstyles.languageButtonText}>English</Text>
          </TouchableOpacity>
        </View>
      </View>

      {/* Lien vers les Conditions d'utilisation */}
      <View style={setstyles.section}>
        <TouchableOpacity>
          <Text style={setstyles.linkText}>Conditions d'utilisation</Text>
        </TouchableOpacity>
      </View>

      {/* Section de Déconnexion */}
      <View style={setstyles.section}>
        <TouchableOpacity style={setstyles.smallButton} onPress={handleSignOut}>
          <Text style={setstyles.buttonText}>Déconnexion</Text>
        </TouchableOpacity>
      </View>

      {/* Footer */}
      <View style={setstyles.footer}>
        <Text style={setstyles.footerText}>© 2024 Burkin'Up</Text>
      </View>
    </View>
  );
};


const setstyles = {
  container: {
    flex: 1,
    backgroundColor: '#f2f2f2',
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  section: {
    backgroundColor: '#fff',
    padding: 15,
    borderRadius: 10,
    marginBottom: 15,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
  },
  label: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
  },
  switchContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  switchLabel: {
    fontSize: 16,
  },
  input: {
    width: '100%',
    padding: 10,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10,
  },
  smallButton: {
    padding: 10,
    backgroundColor: '#007bff',
    borderRadius: 5,
    alignItems: 'center',
    marginVertical: 5,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: '80%',
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  modalButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  modalButton: {
    padding: 10,
    backgroundColor: '#007bff',
    borderRadius: 5,
    marginHorizontal: 5,
  },
  languageContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  languageButton: {
    padding: 10,
    backgroundColor: '#e0e0e0',
    borderRadius: 5,
    flex: 1,
    alignItems: 'center',
    marginHorizontal: 5,
  },
  languageButtonSelected: {
    padding: 10,
    backgroundColor: '#007bff',
    borderRadius: 5,
    flex: 1,
    alignItems: 'center',
    marginHorizontal: 5,
  },
  languageButtonText: {
    color: '#fff',
    fontSize: 16,
  },
  footer: {
    marginTop: 20,
    alignItems: 'center',
  },
  footerText: {
    fontSize: 14,
    color: '#888',
  },
  linkText: {
    color: '#007bff',
    textDecorationLine: 'underline',
    fontSize: 16,
    textAlign: 'center',
  },
};



// --- PrivacyPolicy Screen ---
const PrivacyPolicyScreen = () => {
  const [termsOfService, setTermsOfService] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const snapshot = await firestore().collection('terms').get(); // Récupérer tous les documents de la collection

        const termsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(), // Récupérer les données de chaque document
        }));

        setTermsOfService(termsData);
      } catch (error) {
        console.error("Erreur lors de la récupération des CGU : ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTerms();
  }, []);

  return (
    <View style={privacystyles.container}>
      {loading ? (
        <ActivityIndicator size="large" color="#0000ff" />
      ) : (
        <ScrollView style={privacystyles.scrollView}>
          <Text style={privacystyles.title}>
            Conditions Générales d'Utilisation de Burkin'UP
          </Text>

          {termsOfService.map((section) => (
            <View key={section.id} style={privacystyles.section}>
              <Text style={privacystyles.sectionTitle}>{section.title}</Text>
              <Text style={privacystyles.paragraph}>{section.content}</Text>
            </View>
          ))}

          <Text style={privacystyles.footerText}>
            © 2024 Burkin'Up. Tous droits réservés.
          </Text>
        </ScrollView>
      )}
    </View>
  );
};

const privacystyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F5F5F5',
    paddingHorizontal: '5%',
    paddingVertical: 20,
  },
  scrollView: {
    flex: 1,
    width: '100%',
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  section: {
    marginBottom: 20,
    padding: 10,
    backgroundColor: '#FFF',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  paragraph: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'justify',
  },
  footerText: {
    fontSize: 14,
    color: '#888',
    textAlign: 'center',
    marginTop: 20,
  },
});



const AdminScreen = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const [responses, setResponses] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleAdminAccess = async () => {
    const db = getFirestore();
    let combinedResponses = [];

    // Rechercher dans la collection `training` (réponses aux formations)
    const trainingQuery = query(collection(db, 'training'), where('trainingId', '==', accessCode));
    const trainingSnapshot = await getDocs(trainingQuery);
    if (!trainingSnapshot.empty) {
      const trainingResponses = trainingSnapshot.docs.map(doc => ({
        type: 'Training',
        trainingName: doc.data().trainingName,
        userName: doc.data().userName,
        userPhone: doc.data().userPhone,
      }));
      combinedResponses = [...combinedResponses, ...trainingResponses];
    }

    // Rechercher dans la collection `certificate` (réponses aux opportunités)
    const opportunityQuery = query(collection(db, 'certificate'), where('opportunityId', '==', accessCode));
    const opportunitySnapshot = await getDocs(opportunityQuery);
    if (!opportunitySnapshot.empty) {
      const opportunityResponses = opportunitySnapshot.docs.map(doc => ({
        type: doc.data().opportunityType,
        userEmail: doc.data().userEmail,
        userName: doc.data().userName,
        userSurname: doc.data().userSurname,
      }));
      combinedResponses = [...combinedResponses, ...opportunityResponses];
    }

    // Rechercher dans la collection `mentorRequests` (demandes de mentorat)
    const mentorQuery = query(collection(db, 'mentorRequests'), where('mentorName', '==', accessCode));
    const mentorSnapshot = await getDocs(mentorQuery);
    if (!mentorSnapshot.empty) {
      const mentorResponses = mentorSnapshot.docs.map(doc => ({
        type: 'Mentorat',
        requestMessage: doc.data().requestMessage,
        userEmail: doc.data().userEmail,
        userName: doc.data().userName,
      }));
      combinedResponses = [...combinedResponses, ...mentorResponses];
    }

    if (combinedResponses.length > 0) {
      setResponses(combinedResponses);
      setIsAuthenticated(true);
      setModalVisible(false);
    } else {
      Alert.alert('Erreur', 'Code d\'accès invalide ou aucune réponse trouvée.');
    }
  };

  return (
    <View style={adminstyles.container}>
      <Button title="Admin" onPress={() => setModalVisible(true)} />

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={adminstyles.modalContainer}>
          <View style={adminstyles.modalContent}>
            <Text style={adminstyles.modalTitle}>Entrez le code d'accès</Text>
            <TextInput
              style={adminstyles.input}
              placeholder="Code d'accès"
              value={accessCode}
              onChangeText={setAccessCode}
            />
            <Button title="Valider" onPress={handleAdminAccess} />
            <Button title="Annuler" onPress={() => setModalVisible(false)} />
          </View>
        </View>
      </Modal>

      {isAuthenticated && (
        <View style={adminstyles.responseContainer}>
          <Text style={adminstyles.title}>Réponses associées</Text>
          <FlatList
            data={responses}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item }) => (
              <View style={adminstyles.card}>
                <Text style={adminstyles.label}>Type: {item.type}</Text>
                {item.trainingName && <Text style={adminstyles.label}>Formation: {item.trainingName}</Text>}
                {item.userName && <Text style={adminstyles.label}>Nom: {item.userName}</Text>}
                {item.userPhone && <Text style={adminstyles.label}>Téléphone: {item.userPhone}</Text>}
                {item.userSurname && <Text style={adminstyles.label}>Prénom: {item.userSurname}</Text>}
                {item.userEmail && <Text style={adminstyles.label}>Email: {item.userEmail}</Text>}
                {item.requestMessage && <Text style={adminstyles.label}>Message: {item.requestMessage}</Text>}
              </View>
            )}
          />
        </View>
      )}

      <Footer />
    </View>
  );
};

const Footer = () => (
  <View style={{ padding: 10, alignItems: 'center' }}>
    <Text>© 2024 Burkin'Up. Tous droits réservés.</Text>
  </View>
);

const FindTrainerScreen = () => {
  const [trainers, setTrainers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const [requestMessage, setRequestMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchTrainers = async () => {
      const trainerCollection = collection(firestore, 'trainers');
      const trainerSnapshot = await getDocs(trainerCollection);
      const trainerList = trainerSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTrainers(trainerList);
    };

    fetchTrainers();
  }, []);

  const handleContactTrainer = async () => {
    if (selectedTrainer && requestMessage) {
      const user = auth.currentUser;
      await addDoc(collection(firestore, 'trainerRequests'), {
        trainerName: `${selectedTrainer.firstName} ${selectedTrainer.lastName}`,
        userName: user.displayName || user.email,
        userEmail: user.email,
        requestMessage,
      });

      // Augmenter le compteur de contacts de 1
      const trainerRef = doc(firestore, 'trainers', selectedTrainer.id);
      await updateDoc(trainerRef, {
        contactsCount: (selectedTrainer.contactsCount || 0) + 1,
      });

      setModalVisible(false);
      setRequestMessage('');
      alert('Votre demande a été envoyée avec succès.');
    } else {
      alert('Veuillez entrer un message avant d\'envoyer.');
    }
  };

  const handleRating = async (trainerId, rating) => {
    try {
      const trainerRef = doc(firestore, 'trainers', trainerId);
      const user = auth.currentUser;
      const userRatingRef = doc(firestore, 'trainers', trainerId, 'ratings', user.uid);

      const userRatingDoc = await getDoc(userRatingRef);

      let newRatingCount = 0;
      let newRatingTotal = 0;

      if (userRatingDoc.exists()) {
        const oldRating = userRatingDoc.data().rating;
        const trainerDoc = await getDoc(trainerRef);
        const trainerData = trainerDoc.data();

        newRatingTotal = trainerData.ratingTotal - oldRating + rating;
        newRatingCount = trainerData.ratingCount;

        await updateDoc(userRatingRef, {
          rating: rating,
        });
      } else {
        const trainerDoc = await getDoc(trainerRef);
        const trainerData = trainerDoc.data();

        newRatingTotal = (trainerData.ratingTotal || 0) + rating;
        newRatingCount = (trainerData.ratingCount || 0) + 1;

        await setDoc(userRatingRef, {
          rating: rating,
        });
      }

      const newAverageRating = newRatingTotal / newRatingCount;

      await updateDoc(trainerRef, {
        rating: newAverageRating,
        ratingCount: newRatingCount,
        ratingTotal: newRatingTotal,
      });

      // Envoi de notification de succès via Expo
      console.log('Note mise à jour avec succès! Nouvelle moyenne :', newAverageRating);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la note :', error);
    }
  };

  // Fonction pour filtrer les entraîneurs par spécialité
  const filteredTrainers = trainers.filter(trainer =>
    trainer.specialty.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <ScrollView contentContainerStyle={thstyles.container}>
      <TextInput
        style={thstyles.searchInput}
        placeholder="Rechercher par spécialité"
        value={searchTerm}
        onChangeText={setSearchTerm}
      />
      {filteredTrainers.map(trainer => (
        <View key={trainer.id} style={thstyles.mentorCard}>
          <Image source={{ uri: trainer.photoUrl }} style={thstyles.mentorImageCircle} />
          <Text style={thstyles.mentorName}>{trainer.firstName} {trainer.lastName}</Text>
          <Text style={thstyles.mentorSpecialty}>{trainer.specialty || 'Aucune spécialité spécifiée'}</Text>

          <View style={thstyles.ratingContainer}>
            {[1, 2, 3, 4, 5].map(star => (
              <TouchableOpacity key={star} onPress={() => handleRating(trainer.id, star)}>
                <FontAwesome
                  name="star"
                  size={24}
                  color={trainer.rating >= star ? "gold" : "gray"}
                />
              </TouchableOpacity>
            ))}
          </View>

          <Text style={thstyles.contactsCount}>
            {trainer.contactsCount || 0} contacts
          </Text>

          <TouchableOpacity
            style={thstyles.contactButton}
            onPress={() => {
              setSelectedTrainer(trainer);
              setModalVisible(true);
            }}
          >
            <Text style={thstyles.contactButtonText}>Contactez-le</Text>
          </TouchableOpacity>
        </View>
      ))}

      {selectedTrainer && (
        <Modal
          visible={modalVisible}
          transparent={true}
          animationType="slide"
        >
          <View style={thstyles.modalContainer}>
            <View style={thstyles.modalContent}>
              <Text style={thstyles.modalTitle}>Pourquoi avez-vous besoin de {selectedTrainer.firstName} ?</Text>
              <TextInput
                style={thstyles.input}
                placeholder="Expliquez-vous clairement"
                value={requestMessage}
                onChangeText={setRequestMessage}
                multiline={true}
              />
              <Button title="Envoyer" onPress={handleContactTrainer} />
              <Button title="Annuler" onPress={() => setModalVisible(false)} />
            </View>
          </View>
        </Modal>
      )}
    </ScrollView>
  );
};

const thstyles = {
  container: {
    flexGrow: 1,
    padding: 10,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  searchInput: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginBottom: 15,
  },
  mentorCard: {
    width: '60%', // Ajuste la largeur pour afficher 3 conteneurs par ligne
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: 8,
    padding: 10,
    marginBottom: 15,
    alignItems: 'center',
    elevation: 3, // Ombre pour les conteneurs
  },
  mentorImageCircle: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginBottom: 10,
  },
  mentorName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  mentorSpecialty: {
    fontSize: 14,
    color: 'gray',
  },
  ratingContainer: {
    flexDirection: 'row',
    marginVertical: 5,
  },
  contactsCount: {
    fontSize: 12,
    color: 'gray',
  },
  contactButton: {
    backgroundColor: '#4CAF50',
    padding: 10,
    borderRadius: 5,
    marginTop: 10,
  },
  contactButtonText: {
    color: '#fff',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    width: '80%',
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  input: {
    height: 100,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
  },
};



const GovInfoScreen = () => {
  const [govInfos, setGovInfos] = useState([]);
  const [searchText, setSearchText] = useState('');
  const firestore = getFirestore();

  useEffect(() => {
    const fetchGovInfos = async () => {
      const govInfoCollection = collection(firestore, 'GovInfos');
      const govInfoSnapshot = await getDocs(govInfoCollection);
      const govInfoList = govInfoSnapshot.docs.map(doc => doc.data());
      setGovInfos(govInfoList); // Stocke tous les documents récupérés
    };

    fetchGovInfos();
  }, [firestore]);

  const handleLearnMorePress = (link) => {
    if (link) {
      Linking.openURL(link);
    }
  };

  const filteredGovInfos = govInfos.filter((info) =>
    info.title.toLowerCase().includes(searchText.toLowerCase())
  );

  if (govInfos.length === 0) {
    return <Text>Loading...</Text>;
  }

  return (
    <View style={{ flex: 1 }}>
      {/* Header avec le titre et le champ de recherche */}
      <View style={govstyles.fixedHeader}>
        <Text style={govstyles.pageTitle}>Informations Gouvernementales</Text>
        <TextInput
          style={govstyles.searchInput}
          placeholder="Rechercher par titre..."
          value={searchText}
          onChangeText={setSearchText}
        />
      </View>

      {/* Contenu scrollable avec barre de défilement visible */}
      <ScrollView
        contentContainerStyle={govstyles.scrollContainer}
        showsVerticalScrollIndicator={true} // Toujours afficher la barre de défilement
        style={govstyles.scrollView}
      >
        {filteredGovInfos.length > 0 ? (
          filteredGovInfos.map((govInfo, index) => (
            <View key={index} style={govstyles.infoContainer}>
              <View style={govstyles.headerContainer}>
                <Text style={govstyles.title}>{govInfo.title}</Text>
              </View>
              <Text style={govstyles.subtitle}>{govInfo.subtitle}</Text>

              {/* Affichage du titre de l'image */}
              {govInfo.imageTitle && (
                <Text style={govstyles.imageTitle}>{govInfo.imageTitle}</Text>
              )}

              {/* Affichage de l'image responsive avec dimension limitée */}
              {govInfo.photoUrl ? (
                <View style={govstyles.imageContainer}>
                  <Image
                    source={{ uri: govInfo.photoUrl }}
                    style={govstyles.image} // Style mis à jour pour des dimensions limitées
                    resizeMode="contain" // Ajuste l'image pour qu'elle soit entièrement visible dans le conteneur
                  />
                </View>
              ) : (
                <Text style={govstyles.noImageText}>Image non disponible</Text>
              )}

              <View style={govstyles.messageContainer}>
                <Text style={govstyles.message}>{govInfo.message}</Text>
              </View>
              <Button
                title="En savoir plus"
                onPress={() => handleLearnMorePress(govInfo.link)}
                color="#007bff"
              />
              <Text style={govstyles.warning}>
                Attention : vérifiez régulièrement les mises à jour de cette page pour rester informé.
              </Text>
            </View>
          ))
        ) : (
          <Text style={govstyles.noResultText}>Aucune information trouvée</Text>
        )}
      </ScrollView>

      {/* Footer */}
      <View style={govstyles.footer}>
        <Text style={govstyles.footerText}>© 2024 GovInfo. Tous droits réservés.</Text>
      </View>
    </View>
  );
};

const govstyles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: '#F5F5F5',
    paddingHorizontal: 16,
  },
  fixedHeader: {
    backgroundColor: '#fff',
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  pageTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  searchInput: {
    borderColor: '#ddd',
    borderWidth: 1,
    borderRadius: 8,
    padding: 10,
    marginBottom: 10,
    fontSize: 16,
  },
  scrollContainer: {
    paddingBottom: 20,
  },
  infoContainer: {
    marginBottom: 20,
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  subtitle: {
    fontSize: 16,
    color: 'green', // Sous-titre en vert
    marginBottom: 12,
  },
  imageTitle: {
    fontSize: 16,
    fontStyle: 'italic', // Titre de l'image en italique
    color: '#333',
    textAlign: 'center',
    marginBottom: 8,
  },
  imageContainer: {
    width: '100%',
    maxWidth: 400, // Limite de largeur pour les grands écrans
    maxHeight: 300, // Limite de hauteur pour les grands écrans
    aspectRatio: 1.5, // Maintient une proportion 3:2
    marginBottom: 12,
    borderRadius: 8,
    overflow: 'hidden',
    alignSelf: 'center', // Centre l'image dans son conteneur
    backgroundColor: '#f0f0f0',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  messageContainer: {
    marginBottom: 12,
  },
  message: {
    fontSize: 16,
    color: '#333',
  },
  noImageText: {
    fontSize: 14,
    color: 'gray',
    marginBottom: 8,
    textAlign: 'center',
  },
  warning: {
    fontSize: 14,
    color: '#FF6347',
    marginTop: 10,
  },
  scrollView: {
    flex: 1,
    scrollbarWidth: 'thin', // Pour forcer l'affichage de la barre de défilement sur Desktop/Web
    scrollbarColor: '#007bff #f5f5f5', // Couleur de la barre de défilement
  },
  footer: {
    padding: 16,
    backgroundColor: '#F5F5F5',
    borderTopWidth: 1,
    borderTopColor: '#ddd',
    alignItems: 'center',
  },
  footerText: {
    fontSize: 14,
    color: '#999',
  },
  buttonLearnMore: {
    alignSelf: 'center', // Centre le bouton horizontalement
    paddingHorizontal: 60, // Réduit la largeur du bouton
    paddingVertical: 10, // Hauteur du bouton réduite
    backgroundColor: '#007bff', // Couleur de fond
    borderRadius: 5, // Coins arrondis
    marginTop: 10, // Espace au-dessus du bouton
  },
  buttonText: {
    color: '#fff', // Couleur du texte
    fontSize: 14, // Réduit la taille du texte
    fontWeight: 'bold', // Texte en gras
    textAlign: 'center', // Centre le texte à l'intérieur du bouton
  },
});







const FundingScreen = () => {
  const [fundingInfos, setFundingInfos] = useState([]);
  const firestore = getFirestore();

  useEffect(() => {
    const fetchFundingInfos = async () => {
      const fundingCollection = collection(firestore, 'FundingInfos');
      const fundingSnapshot = await getDocs(fundingCollection);
      const fundingList = fundingSnapshot.docs.map(doc => doc.data());
      setFundingInfos(fundingList); // Stocker tous les documents récupérés
    };

    fetchFundingInfos();
  }, [firestore]);

  const handleApplyPress = (applyLink) => {
    if (applyLink) {
      Linking.openURL(applyLink);
    }
  };

  if (fundingInfos.length === 0) {
    return <Text>Loading...</Text>;
  }

  return (
    <View style={fundstyles.mainContainer}>
      <Text style={fundstyles.pageTitle}>Financement Disponible</Text>

      {/* Barre de recherche */}
      <TextInput
        style={fundstyles.searchInput}
        placeholder="Rechercher un financement..."
      />

      {/* Contenu scrollable */}
      <ScrollView
        contentContainerStyle={fundstyles.scrollContainer}
        showsVerticalScrollIndicator={true} // Pour afficher la barre de défilement
        scrollEnabled={true}  // Assurer que le scroll est activé
      >
        {fundingInfos.map((fundingInfo, index) => (
          <View key={index} style={fundstyles.infoContainer}>
            <View style={fundstyles.headerContainer}>
              <Text style={fundstyles.title}>{fundingInfo.title}</Text>
            </View>
            <Text style={fundstyles.subtitle}>{fundingInfo.subtitle}</Text>
            <View style={fundstyles.imageContainer}>
              <Image source={{ uri: fundingInfo.imageUrl }} style={fundstyles.image} />
            </View>
            <View style={fundstyles.messageContainer}>
              <Text style={fundstyles.message}>{fundingInfo.message}</Text>
            </View>
            <View style={fundstyles.buttonContainer}>
              <Button title="Postuler" onPress={() => handleApplyPress(fundingInfo.applyLink)} color="#28a745" />
            </View>
          </View>
        ))}
      </ScrollView>

      {/* Footer */}
      <View style={fundstyles.footer}>
        <Text style={fundstyles.footerText}>Financements mis à jour régulièrement.</Text>
      </View>
    </View>
  );
};


const fundstyles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 20,
  },
  pageTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  searchInput: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
    padding: 10,
    marginBottom: 20,
  },
  scrollContainer: {
    paddingBottom: 100, // Espace suffisant en bas pour le scroll
  },
  infoContainer: {
    marginBottom: 30, // Espacement entre chaque projet
  },
  headerContainer: {
    marginBottom: 20,
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#007bff', // Bleu pour le titre
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 18,
    fontWeight: '600',
    color: '#ff4c4c', // Rouge pour le sous-titre
    textAlign: 'center',
    marginBottom: 10,
  },
  imageContainer: {
    width: '100%',
    height: 250, // Rectangle pour l'image
    marginBottom: 20,
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
    borderRadius: 10, // Coins légèrement arrondis
  },
  messageContainer: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: '#f9f9f9',
    borderRadius: 5,
    marginBottom: 20,
  },
  message: {
    fontSize: 16,
    color: '#333',
    lineHeight: 24,
    textAlign: 'justify',
  },
  buttonContainer: {
    marginTop: 20,
    alignItems: 'center',
  },
  footer: {
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    fontSize: 16,
    color: '#555',
  },
});



const loginStyles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%', // Prend toute la largeur de l'écran
    height: '100%', // Prend toute la hauteur de l'écran
    justifyContent: 'center',
    alignItems: 'center', // Centrer le contenu horizontalement
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center', // Centrer le contenu horizontalement
  },
  scrollView: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center', // Centrer les éléments dans le ScrollView
  },
  loginContainer: {
    width: '90%', // Ajuster pour plus de réactivité sur les petits écrans
    maxWidth: 400, // Limiter la largeur sur les grands écrans
    padding: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    borderRadius: 10,
    alignItems: 'center',
  },
  loginTitle: {
    fontSize: 24,
    color: '#fff',
    marginBottom: 20,
    fontWeight: 'bold',
    textAlign: 'center', // Centrer le titre
  },
  loginInput: {
    width: '100%', // S'assurer que les champs occupent toute la largeur disponible
    maxWidth: 350, // Limiter la largeur des champs sur les écrans larges
    height: 45, // Augmenter la hauteur pour plus de confort
    backgroundColor: '#333', // Dark background for input fields
    borderRadius: 5,
    color: '#fff',
    paddingHorizontal: 15,
    marginBottom: 15,
    textAlign: 'center', // Centrer le texte à l'intérieur des champs
  },
  loginButton: {
    width: '60%',
    height: 45, // Ajuster la hauteur pour plus de clarté
    backgroundColor: '#0000FF', // Couleur du bouton
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  loginButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  signupLink: {
    marginTop: 10,
  },
  signupText: {
    color: '#fff',
  },
  linkText: {
    color: '#ADD8E6', // Couleur des liens
    fontWeight: 'bold',
  },
  footer: {
    textAlign: 'center',
    color: '#fff',
    marginBottom: 20,
    fontSize: 12,
    fontWeight: 'normal',
  },
});
 

const resourcesStyles = {
  background: {
    flex: 1,
    padding: 20,
    backgroundColor: '#f5f5f5', // Neutral dark white background
  },
  container: {
    paddingBottom: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#333', // Darker text for contrast
    textAlign: 'center',
    marginBottom: 20,
  },
  searchInput: {
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
    fontSize: 16,
    marginBottom: 20,
    borderWidth: 1,
    borderColor: '#ccc',
  },
  linkContainer: {
    backgroundColor: '#fff',
    padding: 15,
    borderRadius: 10,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 5,
  },
  link: {
    fontSize: 18,
    fontWeight: '600',
    color: '#007bff',
    textDecorationLine: 'underline',
  },
  noResults: {
    fontSize: 16,
    color: '#333',
    textAlign: 'center',
  },
};







const trainstyles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 16,
    backgroundColor: '#F5F5F5',
  },
  title: {
    fontSize: 24, // Taille du titre
    fontWeight: 'bold', // Gras pour le titre
    marginBottom: 20, // Espacement sous le titre
    textAlign: 'center', // Centre le titre
    color: '#333', // Couleur du titre
  },
  searchInput: {
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginBottom: 20,
  },
  trainingCard: {
    backgroundColor: '#FFF',
    borderRadius: 8,
    padding: 24, // Augmenter la taille du trainingCard
    marginBottom: 16,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    elevation: 3,
  },
  trainingTitle: {
    fontSize: 20, // Taille plus grande pour le titre de la formation
    fontWeight: 'bold', // Mettre le titre en gras
    marginBottom: 8,
    color: '#333',
  },
  learnMoreButton: {
    backgroundColor: '#FF8C00',
    borderRadius: 8,
    paddingVertical: 8, // Réduire la taille du bouton "En savoir plus"
    paddingHorizontal: 12,
    alignItems: 'center',
  },
  buttonText: {
    color: '#FFF',
    fontSize: 16,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 20,
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#FFF',
  },
  modalText: {
    fontSize: 16,
    marginBottom: 10,
    color: '#FFF',
  },
  input: {
    backgroundColor: '#FFF',
    borderRadius: 8,
    padding: 10,
    marginBottom: 10,
    width: '100%',
  },
  registerButton: {
    backgroundColor: '#FF8C00',
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
    marginBottom: 10,
  },
  cancelButton: {
    backgroundColor: '#FF6347',
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
  },
});



const oppstyles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
  },
  fixedHeader: {
    padding: 16,
    backgroundColor: '#F5F5F5',
    borderBottomWidth: 1,
    borderBottomColor: '#CCC',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#333',
  },
  searchInput: {
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    backgroundColor: '#FFF',
  },
  scrollContainer: {
    flexGrow: 1,
    padding: 16,
    paddingLeft: 40, // Décalage pour la barre de défilement à gauche
  },
  opportunityCard: {
    backgroundColor: '#FFF',
    borderRadius: 8,
    padding: 24,
    marginBottom: 16,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    elevation: 3,
  },
  opportunityType: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
    color: '#228B22', // Vert pour le type d'opportunité
  },
  opportunityTitle: {
    fontSize: 16,
    marginBottom: 8,
    color: '#333',
  },
  learnMoreButton: {
    backgroundColor: '#FF8C00',
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  buttonText: {
    color: '#FFF',
    fontSize: 16,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 20,
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#FFF',
  },
  modalText: {
    fontSize: 16,
    marginBottom: 10,
    color: '#FFF',
  },
  input: {
    backgroundColor: '#FFF',
    borderRadius: 8,
    padding: 10,
    marginBottom: 10,
    width: '100%',
  },
  registerButton: {
    backgroundColor: '#FF8C00',
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
    marginBottom: 10,
  },
  cancelButton: {
    backgroundColor: '#FF6347',
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  footer: {
    padding: 16,
    alignItems: 'center',
  },
  footerText: {
    color: '#FFF',
  },
});



const adminstyles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#f8f9fa',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: 300,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  input: {
    height: 40,
    borderColor: '#ccc',
    borderWidth: 1,
    marginBottom: 15,
    paddingHorizontal: 10,
  },
  responseContainer: {
    marginTop: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  card: {
    padding: 15,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10,
    backgroundColor: '#fff',
  },
  label: {
    fontSize: 16,
    marginBottom: 5,
  },
});

const Stack = createStackNavigator();

const App = () => {
  return (
    <>
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            headerStyle: Tstyles.header,
            headerTitle: '',  // Titre vide pour supprimer les noms des pages
            headerTintColor: 'white',
            headerBackTitleVisible: false,
            headerLeft: ({ onPress }) => (
              <View style={Tstyles.backButtonContainer}>
                <Text onPress={onPress} style={Tstyles.backButton}>⬅️ Retour</Text>
              </View>
            ),
          }}
        >
          <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false }} />
          <Stack.Screen name="Signup" component={SignupScreen} />
          <Stack.Screen name="Login" component={LoginScreen} />
          <Stack.Screen name="Resources" component={ResourcesScreen} />
          <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} />
          <Stack.Screen name="Dashboard" component={DashboardScreen} />
          <Stack.Screen name="Settings" component={SettingsScreen} />
          <Stack.Screen name="FindMentor" component={FindMentorScreen} />
          <Stack.Screen name="Training" component={TrainingScreen} />
          <Stack.Screen name="Opportunities" component={OpportunitiesScreen} />
          <Stack.Screen name="Admin" component={AdminScreen} />
          <Stack.Screen name="Splash" component={SplashScreen} />
          <Stack.Screen name="FindCoach" component={FindCoachScreen} />
          <Stack.Screen name="FindTrainer" component={FindTrainerScreen} />
          <Stack.Screen name="GovernmentInfo" component={GovInfoScreen} />
          <Stack.Screen name="Funding" component={FundingScreen} />
        </Stack.Navigator>
      </NavigationContainer>

      {/* Toast Notification */}
      <Toast ref={(ref) => Toast.setRef(ref)} position="top" />
    </>
  );
};

const Tstyles = StyleSheet.create({
  header: {
    backgroundColor: 'blue',
    height: 40, // Hauteur ajustable du header, modifiez la valeur pour agrandir ou diminuer
    alignItems: 'center',
    justifyContent: 'center',
  },
  backButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10,
  },
  backButton: {
    color: 'white',
    fontSize: 16,
  },
});



export default App;
